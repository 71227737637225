import {PhotoPile} from '@digicomp/photopile-js/js/photopile';
import 'jquery-ui';
import 'jquery-ui/ui/widgets/draggable'
import 'jquery-ui-touch-punch';

window.addEventListener('load', async () => {
    const images = [];
    const piles = Array.from(document.querySelectorAll('[data-late-pile]'));
    piles.forEach(container => {
        for (const image of container.content.querySelectorAll('img')) {
            const element = new Image()
            element.src = image.src;
            images.push(new Promise((resolve => element.addEventListener('load', resolve))));
        }
    });

    await Promise.all(images);
    piles.forEach(container => {
        container.parentElement.innerHTML = container.innerHTML;
    });

    PhotoPile({loading: '/_Resources/Static/Packages/ZSL.SchulkunstArchiv/loading.gif'}).scatter();
});
